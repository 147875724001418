import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {AuthProvider} from "./Context/AuthContext";
import PrivateRoute from "./Models/PrivateRoute";

import SignUp_Page from './Pages/SignUp_Page';
import CatchAllPage from './CatchAllPage';
import Home_Page from "./Pages/Home_Page";
import Login_Page from "./Pages/LogIn_Page";
import About_Page from "./Pages/About_Page";
import Contact_Page from "./Pages/Contact_Page";
import Pricing_Page from "./Pages/Pricing_Page";
import Dashboard_Page from "./Pages/Dashboard_Page";

import HomePageContent from '../src/Pages/JSON/Home_Page.json';
import SignUpPageContent from '../src/Pages/JSON/Signup_Page.json';
import LoginPageContent from '../src/Pages/JSON/Login_Page.json';
import AboutPageContent from '../src/Pages/JSON/About_Page.json';
import ContactPageContent from '../src/Pages/JSON/Contact_Page.json';
import PricingPageContent from '../src/Pages/JSON/Pricing_Page.json';
import DashboardPageContent from '../src/Pages/JSON/Dashboard_Page.json';

// eslint-disable-next-line no-lone-blocks
{/*
    Private Route: <Route path="/pricing" element={<PrivateRoute element={<Pricing_Page content={PricingPageContent}/>} />} />

    Public Route: <Route path="/" element={<Home_Page content={HomePageContent}/>} />
*/}

const App = () => {
    return (
        <AuthProvider>
            <Routes>
                <Route path="/" element={<Home_Page content={HomePageContent}/>} />
                <Route path="/signup" element={<SignUp_Page content={SignUpPageContent}/>} />
                <Route path="/login" element={<Login_Page content={LoginPageContent}/>} />
                <Route path="/aboutus" element={<About_Page content={AboutPageContent}/>} />
                <Route path="/contactus" element={<Contact_Page content={ContactPageContent}/>} />
                <Route path="/pricing" element={<Pricing_Page content={PricingPageContent}/>} />
                <Route path="/dashboard" element={<PrivateRoute element={<Dashboard_Page content={DashboardPageContent}/>} />} />
                <Route path="*" element={<CatchAllPage />} />
            </Routes>
        </AuthProvider>
    );
};

export default App;