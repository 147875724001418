import { useEffect, useState } from "react";
import { BuilderComponent, builder, useIsPreviewing } from "@builder.io/react";

// Put your API key here
builder.init('ac5245d40be444c68001c3bb5c294cc4');

export default function CatchAllPage() {
    const isPreviewingInBuilder = useIsPreviewing();
    const [notFound, setNotFound] = useState(false);
    const [content, setContent] = useState(null);

    useEffect(() => {
        async function fetchContent() {
            const content = await builder
                .get("page", {
                    url: window.location.pathname
                })
                .promise();

            setContent(content);
            setNotFound(!content);

            if (content?.data.title) {
                document.title = content.data.title;
            }
        }

        fetchContent();
    }, [window.location.pathname]);

    if (notFound && !isPreviewingInBuilder) {
        return <div>404 - Page Not Found</div>;
    }

    return (
        <>
            <BuilderComponent model="page" content={content} />
        </>
    );
}