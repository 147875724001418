import React from 'react';
import { BuilderComponent } from '@builder.io/react';

const Pricing_Page = ({ content }) => {
    return (
        <div>
            <BuilderComponent model="page" content={content} />
        </div>
    );
};

export default Pricing_Page;